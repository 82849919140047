import React from "react"
import styled from "styled-components"

const Hero = () => {
  return (
    <Container id="hot-video">
      <div className="inner">
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/_ie-qrRqdso?si=kENjLPreL6IP0I7d"
          title="Bolognese Music Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: -5% auto 0;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    max-width: 90rem;
  }

  @media ${({ theme }) => theme.mediaQuery.xlarge} {
    max-width: 120rem;
  }

  .inner {
    width: 100%;
    padding: 0 1rem;

    @media ${({ theme }) => theme.mediaQuery.medium} {
      padding: 0;
    }

    iframe {
      display: block;
      aspect-ratio: 16/9;
    }
  }
`

export default Hero
