import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

import GlobalStyle from "./components/globalStyles"

import Home from "./pages/home"
import NFTArt from "./pages/landing/nft-art"
import NFTListening from "./pages/landing/nft-listening"
// import Splash from "./pages/splash"

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/nft-listening">
            <NFTListening />
          </Route>
          <Route path="/nft-listings">
            <NFTArt />
          </Route>
          <Route path="/">
            <Home />
            {/* <Splash /> */}
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default App
