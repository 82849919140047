import React from "react"
import styled from "styled-components"

const Hero = () => {
  return (
    <Container id="hero">
      <Signature>Naiim</Signature>
      {/* <img src={`${process.env.PUBLIC_URL}/img/hero.webp`} alt="" /> */}
      <img
        sizes="(max-width: 1920px) 100vw, 1920px"
        srcSet={`
          ${process.env.PUBLIC_URL}/img/hero_w_300.webp 300w,
          ${process.env.PUBLIC_URL}/img/hero_w_981.webp 981w,
          ${process.env.PUBLIC_URL}/img/hero_w_1414.webp 1414w,
          ${process.env.PUBLIC_URL}/img/hero_w_1757.webp 1757w,
          ${process.env.PUBLIC_URL}/img/hero_w_1920.webp 1920w`}
        src={`${process.env.PUBLIC_URL}/img/hero_w_1920.webp`}
        alt=""
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 70vh;
  min-height: 52vh;
  position: relative;
  background: ${({ theme }) => theme.colors.primary};
  overflow: hidden;

  @media ${({ theme }) => theme.mediaQuery.small} {
    min-height: 35rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    min-height: 60rem;
  }

  img {
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    object-fit: cover;
    object-position: 50% 15%;
    display: block;
  }
`

const Signature = styled.h1`
  font-family: "Streamster", san-serif;
  transform: rotate(-10deg) translateX(-50%) translateY(-50%);
  font-size: 8rem;
  color: white;
  text-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  font-weight: normal;
  position: absolute;
  left: 50%;
  top: 45%;

  @media ${({ theme }) => theme.mediaQuery.small} {
    font-size: 11rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 15rem;
  }
`

export default Hero
