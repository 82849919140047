import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { v4 as uuid } from "uuid"
import { useInView } from "react-intersection-observer"
import CountUp from "react-countup"

import {
  Title as _Title,
  Siding,
} from "../../components/globalStyles"

import { STATS } from "../../utils/variables"

const Stats = () => {
  return (
    <Container id="stats">
      <Main>
        <MobileTitle>
          The <br /> Numbers
        </MobileTitle>
        <BackgroundPanel />
        <ImgContainer>
          {/* <img src={`${process.env.PUBLIC_URL}/img/the-numbers.webp`} alt="" /> */}
          <img
            sizes="(max-width: 1158px) 100vw, 1158px"
            srcSet={`
              ${process.env.PUBLIC_URL}/img/the-numbers_w_300.webp 300w,
              ${process.env.PUBLIC_URL}/img/the-numbers_w_621.webp 621w,
              ${process.env.PUBLIC_URL}/img/the-numbers_w_828.webp 828w,
              ${process.env.PUBLIC_URL}/img/the-numbers_w_1026.webp 1026w,
              ${process.env.PUBLIC_URL}/img/the-numbers_w_1158.webp 1158w`}
            src={`${process.env.PUBLIC_URL}/img/the-numbers_w_1158.webp`}
            alt=""
          />
        </ImgContainer>
        <Content>
          <Title>
            The <br /> Numbers
          </Title>
          <div className="stats">
            {STATS.map((stat) => (
              <Stat key={uuid()}>
                {/* <Counter stat={stat} /> */}
                <div className="number">{stat.number}</div>
                <div className="subtitle">{stat.subtitle}</div>
              </Stat>
            ))}
          </div>
        </Content>
      </Main>
      <Siding className="siding-2">the numbers</Siding>
    </Container>
  )
}

const Counter = ({ number, subtitle }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  const parsedNumber = parseInt(number.replace(",", ""))

  return (
    <>
      <div className="number" ref={ref}>
        <CountUp
          start={0}
          end={inView ? parsedNumber : 0}
          duration={2}
          separator="."
          useEasing={true}
        >
          {({ countUpRef }) => <span ref={countUpRef} />}
        </CountUp>
      </div>
      <div className="subtitle">{subtitle}</div>
    </>
  )
}

Counter.propTypes = {
  number: PropTypes.string,
  subtitle: PropTypes.string,
}

Counter.defaultProps = {
  number: "0",
  subtitle: "",
}

const Container = styled.div`
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 8rem;
  display: flex;
  align-items: flex-start;
`

const Main = styled.div`
  flex: 1 1 100%;
  width: 100%;
  // max-width: 102.4rem;
  margin: 0 0 3rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    gap: 2rem;
  }
`

const BackgroundPanel = styled.div`
  grid-column: 1 / 5;
  grid-row: 1;
  background: ${({ theme }) => theme.colors.primary};
  top: 5rem;
  right: 5rem;
  position: relative;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQuery.small} {
    grid-column: 1/5;
    grid-row: 2;
  }
`

const ImgContainer = styled.div`
  grid-column: 1/5;
  grid-row: 1;
  height: 50rem;
  position: relative;
  z-index: 3;

  @media ${({ theme }) => theme.mediaQuery.small} {
    height: 60rem;
    grid-row: 2;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 80rem;
    grid-column: 1/5;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    height: 100rem;
    grid-column: 1/5;
  }

  @media ${({ theme }) => theme.mediaQuery.xlarge} {
    height: 110rem;
    grid-column: 1/5;
  }

  @media (min-width: 140rem) {
    height: 130rem;
    grid-column: 1/5;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 80% 50%;
  }
`

const Content = styled.div`
  grid-column: 5/9;
  grid-row: 1;
  align-self: flex-start;
  position: relative;
  z-index: 3;
  top: 12rem;

  @media (min-width: 480px) {
    align-self: center;
    top: 2rem;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    grid-column: 5/9;
    grid-row: 2;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    grid-column: 5/9;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    grid-column: 5/9;
  }
`

const Title = styled(_Title)`
  position: relative;
  right: 4rem;
  display: none;
  padding: 0 2rem;

  @media (min-width: 480px) {
    display: block;
    right: 6rem;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    left: -17rem;
    width: calc(100% + 14rem);
    text-align: right;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    left: -10rem;
    width: calc(100% + 10rem);
    text-align: left;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    margin-bottom: 4rem;
  }
`

const MobileTitle = styled(_Title)`
  text-align: right;
  grid-row: 1;
  grid-column: 1/9;
  display: block;
  padding: 0 1rem;
  bottom: -3rem;
  position: relative;
  z-index: 5;

  @media (min-width: 480px) {
    display: none;
  }
`

const Stat = styled.div`
  margin-top: 1rem;

  @media ${({ theme }) => theme.mediaQuery.large} {
    margin-left: 2rem;
  }

  .number {
    font-family: IntegralCF, serif;
    font-weight: 600;
    font-size: 3rem;
    color: transparent;
    letter-spacing: -0.2rem;

    span {
      font-family: IntegralCF, serif;
      font-weight: 600;
    }

    @media ${({ theme }) => theme.mediaQuery.small} {
      font-size: 6rem;
      letter-spacing: 0rem;
    }

    @media ${({ theme }) => theme.mediaQuery.large} {
      font-size: 8rem;
    }

    @supports (-webkit-text-stroke: 1px black) {
      -webkit-text-stroke: 0.1rem
        ${({ theme }) => theme.colors.primary};
      @media ${({ theme }) => theme.mediaQuery.small} {
        -webkit-text-stroke: 0.15rem
          ${({ theme }) => theme.colors.primary};
      }
    }
    @supports not (-webkit-text-stroke: 1px black) {
      color: ${({ theme }) => theme.colors.primary};
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }
  }

  .subtitle {
    font-weight: bold;
    letter-spacing: -0.06rem;

    @media ${({ theme }) => theme.mediaQuery.small} {
      font-size: 2.5rem;
      letter-spacing: -0.1rem;
    }

    @media ${({ theme }) => theme.mediaQuery.large} {
      font-size: 3rem;
    }
  }
`

export default Stats
