import styled, { createGlobalStyle, css } from "styled-components"

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: 'IntegralCF';
    src: local('IntegralCF'), local('Integral CF'),
    url(${process.env.PUBLIC_URL}/fonts/IntegralCF/Bold/IntegralCF-Bold.woff2) format('woff2'),
    url(${process.env.PUBLIC_URL}/fonts/IntegralCF/Bold/IntegralCF-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntegralCF';
    src: local('IntegralCF'), local('Integral CF'),
    url(${process.env.PUBLIC_URL}/fonts/IntegralCF/DemiBold/IntegralCF-DemiBold.woff2) format('woff2'),
    url(${process.env.PUBLIC_URL}/fonts/IntegralCF/DemiBold/IntegralCF-DemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'IntegralCF';
    src: local('IntegralCF'), local('Integral CF'),
    url(${process.env.PUBLIC_URL}/fonts/IntegralCF/Regular/IntegralCF-Regular.woff2) format('woff2'),
    url(${process.env.PUBLIC_URL}/fonts/IntegralCF/Regular/IntegralCF-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Streamster';
    src: local('Streamster'), local('Streamster'),
    url(${process.env.PUBLIC_URL}/fonts/Streamster/Streamster.woff2) format('woff2'),
    url(${process.env.PUBLIC_URL}/fonts/Streamster/Streamster.woff) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  html { font-size: 62.5%; scroll-behavior: smooth; }

  body {
    background: #ffffff;
    font-size: 1.6rem;
  }

  html, body {
    height: 100%;
  }

  #root {
    &:not(.plain) {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
    }
  }

  ion-icon {
    font-size: 2.5rem;
  }
`

const Title = styled.h2`
  font-family: IntegralCF, serif;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
  font-size: 4.4rem;
  line-height: 0.9;
  letter-spacing: -0.1rem;

  @media ${({ theme }) => theme.mediaQuery.small} {
    font-size: 8rem;
    letter-spacing: -0.2rem;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    font-size: 10rem;
    letter-spacing: -0.3rem;
  }
`

const Siding = styled.div`
  text-transform: uppercase;
  transform-origin: center;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  writing-mode: vertical-rl;
  font-family: IntegralCF, serif;
  font-weight: 400;
  font-style: normal;
  display: none;

  @media ${({ theme }) => theme.mediaQuery.xlarge} {
    display: block;
  }

  &.siding-1 {
    align-self: flex-end;
    transform: rotate(180deg);
    margin-left: 5rem;
    margin-bottom: 2rem;
  }

  &.siding-2 {
    writing-mode: vertical-rl;
    margin-right: 5rem;
    margin-top: 2rem;
  }
`

const Paragraph = css`
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: 400;
`

export default GlobalStyle
export { Title, Siding, Paragraph }
