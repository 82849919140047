import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { v4 as uuid } from "uuid"
import { useHistory, useLocation } from "react-router-dom"
import throttle from "lodash.throttle"

import { LINKS } from "../../utils/variables"

import Hero from "../../layouts/hero"
import OffsetWithSiding from "../../layouts/offsetWithSiding"
import BackgroundCarousel from "../../layouts/backgroundCarousel"
import Stats from "../../layouts/stats"
// import Quad from "../../layouts/quad"
import Social from "../../layouts/social"
import Footer from "../../layouts/footer"
import VideoHero from "../../layouts/videoHero"

const Home = () => {
  const nav = useRef()
  const [currentNavElem, setCurrentNavElem] = useState("hero")
  // eslint-disable-next-line no-unused-vars
  const history = useHistory()
  const location = useLocation()

  let lastScrollPosition = 0

  const onScroll = () => {
    const scrollPosition = window.scrollY

    if (nav && nav.current) {
      const visibleSections = [
        ...nav.current.querySelectorAll(".dot"),
      ]
        .map((v) => {
          let refElement = document.querySelector(
            v.getAttribute("href"),
          )
          if (
            refElement?.offsetTop - window.innerHeight <=
              scrollPosition &&
            refElement?.offsetTop + refElement.clientHeight >
              scrollPosition
          ) {
            return refElement.getAttribute("id")
          }
        })
        .filter((item) => {
          return item !== undefined
        })

      if (visibleSections.length > 0) {
        let section = ""
        if (scrollPosition > lastScrollPosition) {
          section = visibleSections[visibleSections.length - 1]
        } else {
          section = visibleSections[0]
        }

        setCurrentNavElem(section)
      }

      lastScrollPosition = scrollPosition <= 0 ? 0 : scrollPosition
    }
  }

  useEffect(() => {
    if (currentNavElem !== "hero") {
      history.push(`#${currentNavElem}`)
    } else {
      history.push("/")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNavElem])

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", onScroll)
    window.addEventListener("scroll", throttle(onScroll, 300))

    if (location.hash) setCurrentNavElem(location.hash.split("#")[1])

    return () => {
      window.removeEventListener("DOMContentLoaded", onScroll)
      window.removeEventListener("scroll", onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Dots ref={nav}>
        {LINKS.map((link) => (
          <a
            key={uuid()}
            href={`#${link.id}`}
            className={`dot${
              link.id === currentNavElem ? " active" : ""
            }`}
          >
            <div className="dot-inner"></div>
          </a>
        ))}
      </Dots>
      <Hero />
      <VideoHero />
      <OffsetWithSiding />
      <BackgroundCarousel />
      <Stats />
      {/* <Quad /> */}
      <Social />
      <Footer />
    </>
  )
}

const Dots = styled.div`
  position: fixed;
  right: 2rem;
  top: 70vh;
  z-index: 10;
  transform: translateY(-120%);
  display: none;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: block;
  }

  .dot {
    display: block;
    padding: 0.75rem;

    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;

    .dot-inner {
      border-radius: 0.4rem;
      background: black;
      width: 0.6rem;
      height: 0.6rem;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;

      .dot-inner {
        height: 2rem;
      }
    }
  }
`

export default Home
