import React from "react"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Title as _Title } from "../../components/globalStyles"

const Social = () => {
  return (
    <Container id="social">
      <Content>
        <LinkGroup>
          <Link href="https://open.spotify.com/artist/4y7ZnYVLA3k3efItckQfuF">
            <FontAwesomeIcon icon={["fab", "spotify"]} />
          </Link>
          <Link href="https://music.apple.com/ca/artist/naiim/1350017032">
            <FontAwesomeIcon icon={["fab", "apple"]} />
          </Link>
          <Link href="https://www.youtube.com/channel/UCk-03O6KqGediKMpYhnMYPA/featured">
            <FontAwesomeIcon icon={["fab", "youtube"]} />
          </Link>
        </LinkGroup>
        <Title>Social</Title>
        <LinkGroup>
          <Link href="https://instagram.com/naiimsounds">
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </Link>
          <Link href="https://twitter.com/naiimsounds">
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </Link>
          <Link href="https://facebook.com/NAIIM-106363534661417">
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </Link>
        </LinkGroup>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  min-height: 51vh;
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.mediaQuery.small} {
    padding-top: 10rem;
    padding-bottom: 16rem;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-wrap: nowrap;
  }
`

const LinkGroup = styled.div`
  margin-top: 1rem;
  flex: 1 1 100%;
  text-align: center;
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex: 0 1 auto;
    margin-top: 1.5rem;
  }
`

const Link = styled.a`
  color: black;
  font-size: 3rem;
  margin: 0 0.75rem;
  opacity: 0.5;
  display: inline-block;
  transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1),
    transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transform: translateY(0);

  &:hover {
    opacity: 1;
    transform: translateY(-0.3rem);
  }
`

const Title = styled(_Title)`
  margin: 0 2rem;
`

export default Social
