import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { v4 as uuid } from "uuid"

import {
  Splide,
  SplideSlide as _SplideSlide,
} from "@splidejs/react-splide"
import "@splidejs/splide/dist/css/themes/splide-default.min.css"

import {
  Title as _Title,
  Paragraph,
} from "../../components/globalStyles"
import { MUSIC } from "../../utils/variables"

const BackgroundCarousel = () => {
  const primary = useRef(null)
  const timeline = useRef(null)

  const songsSliderOptions = {
    type: "slide",
    arrows: false,
    pagination: false,
    focus: "center",
    trimSpace: false,
    perPage: 7,
    padding: {
      right: "6rem",
      left: "10%",
    },
    gap: 20,
    rewind: false,
    breakpoints: {
      1440: {
        perPage: 6,
        padding: {
          right: "6rem",
          left: "10%",
        },
      },
      1200: {
        perPage: 5,
        padding: {
          right: "6rem",
          left: "1rem",
        },
      },
      1024: {
        perPage: 5,
        padding: {
          right: "6rem",
          left: "10%",
        },
      },
      960: {
        perPage: 4,
        padding: {
          right: "6rem",
          left: "1rem",
        },
      },
      800: {
        perPage: 3,
        padding: {
          right: "6rem",
          left: "1rem",
        },
      },
      600: {
        perPage: 2,
        padding: {
          right: "6rem",
          left: "2rem",
        },
      },
      480: {
        perPage: 1,
        padding: {
          right: "6rem",
          left: "2rem",
        },
      },
      400: {
        perPage: 1,
        padding: {
          right: "6rem",
          left: "1rem",
        },
      },
    },
  }

  useEffect(() => {
    window.primary = primary.current
    primary.current.sync(timeline.current.splide)
  }, [])

  const processDate = (date) => {
    const year = date.split(", ")[1]
    const month = date.split(", ")[0].split(" ")[0]
    const day = date.split(", ")[0].split(" ")[1]

    return {
      day,
      month,
      year,
    }
  }

  return (
    <Container id="music">
      <Content>
        <div className="text">
          <Title>
            The
            <br />
            Music
          </Title>
          <p>
            NAIIM’s unique amalgamation of rap, pop and R&amp;B
            follows no formula and is an extension of himself. He is
            recognized for his tone and versatility that shine through
            on every single one of his tracks. His music is
            dichotomous, just like him - and brings out every aspect
            of his personality.
          </p>
        </div>
      </Content>
      <SliderContainer>
        <Splide options={songsSliderOptions} ref={primary}>
          {MUSIC.map((song) => (
            <SplideSlide key={uuid()}>
              <a className="img-container" href={song.url}>
                <img src={song.img} alt="" />
              </a>
            </SplideSlide>
          ))}
        </Splide>
        <Timeline>
          <Splide options={songsSliderOptions} ref={timeline}>
            {MUSIC.map((song) => (
              <SplideSlide key={uuid()}>
                <img className="hidden" src={song.img} alt="" />
                <div className="container">
                  <div className="content">
                    <h5>{processDate(song.release_date).month}</h5>
                    <h6>{processDate(song.release_date).year}</h6>
                  </div>
                  {song.title !== "Alchemy" && (
                    <div className="line">
                      <div></div>
                    </div>
                  )}
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </Timeline>
      </SliderContainer>
      <MobileContent>
        <p>
          NAIIM’s unique amalgamation of rap, pop and R&amp;B follows
          no formula and is an extension of himself. He is recognized
          for his tone and versatility that shine through on every
          single one of his tracks. His music is dichotomous, just
          like him - and brings out every aspect of his personality.
        </p>
      </MobileContent>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  // overflow-x: hidden;

  .hero {
    width: 100%;
    height: 50%;
    object-fit: cover;

    &:after {
      content: " ";

      background: linear-gradient(
        to bottom,
        rgba(245, 246, 252, 0.52),
        rgba(117, 19, 93, 0.73)
      );
    }
  }
`

const Title = styled(_Title)`
  margin-bottom: 2rem;
`

const Content = styled.div`
  overflow-x: hidden;
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 85%
    ),
    url(${`${process.env.PUBLIC_URL}/img/hat-down.webp`});
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 40rem;
  background-size: 175% auto;
  background-position: 80% 60%;
  margin-bottom: -15rem;

  @media (min-width: 480px) {
    margin-bottom: -10rem;
    background-size: 150% auto;
    background-position: 80% center;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    background-position: 70% 50%;
    background-size: 115% auto;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    height: 80rem;
    width: 100%;
    background-position: 80% 40%;
    margin-bottom: -25rem;
    background-size: 155% auto;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    background-position: 80% 60%;
    background-size: 125% auto;
    margin-bottom: -33rem;
  }

  @media ${({ theme }) => theme.mediaQuery.xlarge} {
    background-position: 80% 40%;
    background-size: 100% auto;
    margin-bottom: -30rem;
  }

  .text {
    color: white;
    position: relative;
    top: 20%;
    margin-left: 2rem;
    transform: translateY(-50%);

    @media (min-width: 480px) {
      margin-left: 3rem;
      top: 30%;
    }

    @media ${({ theme }) => theme.mediaQuery.small} {
      margin-left: 3%;
      top: 35%;
    }

    @media ${({ theme }) => theme.mediaQuery.medium} {
      margin-left: 40%;
      top: 30%;
      transform: translateX(-50%) translateY(-50%);
    }

    @media ${({ theme }) => theme.mediaQuery.large} {
      top: 30%;
      margin-left: 35%;
    }

    @media ${({ theme }) => theme.mediaQuery.xlarge} {
      top: 35%;
      margin-left: 40%;
    }

    p {
      max-width: 36rem;
      display: none;
      ${Paragraph}
      text-shadow: 0 0 2px rgba(255, 255, 255, 1);

      @media ${({ theme }) => theme.mediaQuery.medium} {
        display: block;
      }

      @media ${({ theme }) => theme.mediaQuery.large} {
        max-width: 40rem;
      }

      @media ${({ theme }) => theme.mediaQuery.xlarge} {
        max-width: 48rem;
      }
    }
  }
`

const SliderContainer = styled.div`
  position: relative;
  position: relative;
  left: 0%;
  width: 100%;

  @media (min-width: 480px) {
    left: -30%;
    width: 130%;
  }

  @media ${({ theme }) => theme.mediaQuery.small} {
    margin-bottom: 10rem;
    left: -40%;
    width: 140%;
  }

  @media (min-width: 800px) {
    left: -40%;
    width: 140%;
  }

  @media (min-width: 960px) {
    left: -45%;
    width: 145%;
  }

  @media ${({ theme }) => theme.mediaQuery.large} {
    left: -60%;
    width: 160%;
  }

  @media ${({ theme }) => theme.mediaQuery.xlarge} {
    left: -70%;
    width: 170%;
  }

  @media (min-width: 1440px) {
    left: -75%;
    width: 175%;
  }
`

const SplideSlide = styled(_SplideSlide)`
  &.is-active {
    .container {
      .content {
        opacity: 1;
        transform: scale(1.1);
      }

      .line {
        opacity: 1;

        div {
          left: 3rem;
          right: 2rem;
          width: calc(100% - 2rem);
        }
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;

    .content {
      flex: 0 0 auto;
      text-align: left;
      transition: all 0.3s ease;
      opacity: 0.3;
      transform: scale(0.8);
      transform-origin: left center;

      h5 {
        font-family: "IntegralCF", sans-serif;
        font-weight: 600;
        font-size: 3rem;
      }

      h6 {
        font-family: "IntegralCF", sans-serif;
        font-size: 2rem;
      }
    }

    .line {
      position: relative;
      width: 100%;
      opacity: 0.3;
      transition: all 0.3s ease;

      div {
        position: absolute;
        width: 100%;
        border: 2px solid #000;
        left: 0rem;
        right: -2rem;
        transition: all 0.3s ease;
      }
    }
  }

  .img-container {
    display: block;
  }

  img {
    width: 100%;

    &.hidden {
      height: 0px;
    }
  }
`

const Timeline = styled.div`
  width: 100%;
  pointer-events: none;
`

const MobileContent = styled.div`
  display: block;
  padding: 0 1rem;
  margin-top: 4rem;
  margin-bottom: 8rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: none;
  }

  p {
    max-width: 48rem;
    margin: 0 auto;
    ${Paragraph}
  }
`

export default BackgroundCarousel
