import React, { useEffect } from "react"
import styled from "styled-components"
import { MD } from "../../../utils/variables"

const NFTListening = () => {
  const Listening = [
    {
      key: 0,
      hash: "dusty-winds",
      title: "Dusty Winds",
      bg: "#bfa3c0",
      color: "#ffffff",
      img: `https://lh3.googleusercontent.com/X0lBDKfBQVY_eDQ7qvhZN5OJzsPo5zIKaU7OTf5-Jr12dhptWnVz_ewLuiWGWRZFOqRKO8uQBIwzf7saL3_95mm1SIXdQhFLN6qP=w600`,
      url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/8991794967676004904341799851885056855050754394621727503688886152737373290497",
    },
    {
      key: 1,
      hash: "where-do-we-go",
      title: "Where Do We Go",
      bg: "#e2a431",
      color: "#000000",
      img: "https://lh3.googleusercontent.com/4K8fhnlAe5P36KQF50s-WEdI8is6CCwr-6T22NGld9_cyXHFnO1UnwiccOspSQqP9VgahW7XTqUzmk5K9G64YOStIV059Xpn2o3xEA=w600",
      url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/8991794967676004904341799851885056855050754394621727503688886154936396546049",
    },
    {
      key: 2,
      hash: "starburst",
      title: "Starburst",
      bg: "#31e2b6",
      color: "#000000",
      img: "https://lh3.googleusercontent.com/xHG5zjVnwznfB7mCxYO450LrmGKkR2xSGNIlbVSkgdYMlD4vaRAkEkROQettBtA7d5r7VkleEqJECt7BYTLZkII4JUZf9x8clAG7=w600",
      url: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/8991794967676004904341799851885056855050754394621727503688886153836884918273",
    },
  ]

  useEffect(() => {
    document.querySelector("#root").classList.add("plain")

    return () => {
      document.querySelector("#root").classList.remove("plain")
    }
  }, [])

  return (
    <main>
      <NFTContainer>
        {Listening.map((item) => (
          <NFT
            id={item.hash}
            key={item.key}
            bg={item.bg}
            color={item.color}
          >
            <div className="content-container">
              <img src={item.img} alt="" />
              <div className="inner-content">
                <h2>{item.title}</h2>
                <a className="button" href={item.url}>
                  Bid on OpenSea
                </a>
              </div>
            </div>
          </NFT>
        ))}
      </NFTContainer>
      {/* <StickyBar>
        <div className="flex-container">
          <a href="https://untd.io/m/dustywinds22">
            <span>Pre-Save Dusty Winds</span>
          </a>
          <img
            src={`${process.env.PUBLIC_URL}/img/dusty-winds.png`}
            alt=""
          />
        </div>
      </StickyBar> */}
    </main>
  )
}

const NFTContainer = styled.div``

const NFT = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  background: ${({ bg }) => bg};
  padding: 2rem;
  text-align: center;

  @media (min-width: ${MD}px) {
    text-align: left;
    height: 50vh;
    min-height: 48rem;
  }

  .content-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 100%;
      max-width: 40rem;
      height: auto;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border: 1rem solid white;
    }

    .inner-content {
      padding: 2rem;
      color: ${({ color }) => color};
      width: 32rem;

      h2 {
        margin-bottom: 2rem;
        font-size: 3rem;
      }

      .button {
        display: inline-block;
        border: 2px solid ${({ color }) => color};
        color: ${({ color }) => color};
        background: transparent;
        padding: 1rem 2rem;
        text-decoration: none;

        &:hover {
          background: ${({ color }) => color};
          color: ${({ bg }) => bg};
        }
      }
    }
  }
`

// const StickyBar = styled.div`
//   width: 100%;
//   background: #222;
//   padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));
//   position: sticky;
//   bottom: 0;

//   .flex-container {
//     display: flex;
//     max-width: ${SM}px;
//     justify-content: space-between;
//     width: 100%;
//     margin: 0 auto;
//     align-items: center;

//     @media (min-width: ${MD}px) {
//       justify-content: center;
//     }

//     a {
//       display: inline-block;
//       border: 2px solid white;
//       color: white;
//       background: transparent;
//       text-decoration: none;
//       order: 2;
//       height: 6rem;
//       display: inline-flex;
//       align-items: center;
//       padding: 1rem 2rem;
//       margin-right: 1rem;

//       @media (min-width: ${MD}px) {
//         margin-right: 0;
//       }

//       &:active,
//       &:hover {
//         background: white;
//         color: #222;
//       }
//     }

//     img {
//       height: 8rem;
//       width: auto;
//       padding: 1rem;
//       order: 1;
//     }
//   }
// `

export default NFTListening
