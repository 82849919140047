import React from "react"
import styled from "styled-components"
import { Title as _Title } from "../../../components/globalStyles"
import { MD, XXL } from "../../../utils/variables"

const NFTListening = () => {
  const Listening = [
    {
      key: 0,
      title: "Dusty Winds - Single",
      img: `https://lh3.googleusercontent.com/X0lBDKfBQVY_eDQ7qvhZN5OJzsPo5zIKaU7OTf5-Jr12dhptWnVz_ewLuiWGWRZFOqRKO8uQBIwzf7saL3_95mm1SIXdQhFLN6qP=w600`,
      url: "https://song.space/3zxgv3",
    },
    {
      key: 1,
      title: "Where Do We Go - Single",
      img: "https://lh3.googleusercontent.com/4K8fhnlAe5P36KQF50s-WEdI8is6CCwr-6T22NGld9_cyXHFnO1UnwiccOspSQqP9VgahW7XTqUzmk5K9G64YOStIV059Xpn2o3xEA=w600",
      url: "https://song.space/3js8g7",
    },
    {
      key: 2,
      title: "Starburst - Single",
      img: "https://lh3.googleusercontent.com/xHG5zjVnwznfB7mCxYO450LrmGKkR2xSGNIlbVSkgdYMlD4vaRAkEkROQettBtA7d5r7VkleEqJECt7BYTLZkII4JUZf9x8clAG7=w600",
      url: "https://song.space/3eynu9",
    },
  ]

  return (
    <main>
      <TitleContainer>
        <div className="content">
          <Title>Listening Experience</Title>
          <h3>Tap image below to play</h3>
        </div>
      </TitleContainer>
      <MusicContainer>
        {Listening.map((item) => (
          <a key={item.key} href={item.url}>
            <img src={item.img} alt="" />
          </a>
        ))}
      </MusicContainer>
    </main>
  )
}

const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(${process.env.PUBLIC_URL}/img/dusty-winds-cover.png);
  background-size: cover;
  background-position: center;
  min-height: 60rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .content {
    padding: 2rem 5rem;

    h3 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 2.2rem;
      margin-top: 3rem;

      @media (min-width: ${MD}px) {
        font-size: 3rem;
      }
    }
  }
`

const Title = styled(_Title)`
  white-space: pre-line;
  margin-bottom: 2rem;
`

const MusicContainer = styled.div`
  display: flex;
  max-width: ${XXL}px;
  width: 100%;
  margin: 2rem auto;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;

  @media (min-width: ${MD}px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  a {
    display: inline-block;
    margin: 1rem;

    img {
      width: 100%;
      max-width: 40rem;
      height: auto;
      transition: 0.3s ease;

      &:hover,
      &:active {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
      }
    }
  }
`

export default NFTListening
