import React from "react"
import styled from "styled-components"

import {
  Title as _Title,
  Siding,
  Paragraph,
} from "../../components/globalStyles"

const OffsetWithSiding = () => {
  return (
    <Container id="artist">
      <Siding className="siding-1">the artist</Siding>
      <Main>
        <div className="grid">
          <div className="background-panel"></div>
          <div className="col col-1">
            {/* <img
              src={`${process.env.PUBLIC_URL}/img/naiim-profile.webp`}
              alt=""
            /> */}
            <img
              sizes="(max-width: 669px) 100vw, 669px"
              srcSet={`
                ${process.env.PUBLIC_URL}/img/naiim-profile_w_300.webp 300w,
                ${process.env.PUBLIC_URL}/img/naiim-profile_w_515.webp 515w,
                ${process.env.PUBLIC_URL}/img/naiim-profile_w_669.webp 669w
              `}
              src={`${process.env.PUBLIC_URL}/img/naiim-profile_w_669.webp`}
              alt=""
            />
          </div>
          <div className="col col-2">
            <Title>
              The
              <br />
              Artist
            </Title>
            <p>
              Born in Montreal, Canada, NAIIM grew up in a bi-cultural
              household: his dad, a first generation Canadian artist
              from Somaliland, and his mom, a French-Canadian teacher.
              Art and education were the foundation of his upbringing.
              However, it wasn’t until he decided to abandon a career
              in medicine that music became his sole focus.
            </p>

            <p>
              NAIIM’s unique amalgamation of rap, pop and R&amp;B
              follows no formula and is an extension of himself. He is
              recognized for his tone and versatility that shine
              through on every single one of his tracks. His music is
              dichotomous, just like him - and brings out every aspect
              of his personality.
            </p>
          </div>
        </div>
        <MobileContent>
          <MobileTitle>
            The
            <br />
            Artist
          </MobileTitle>
          <p>
            Born in Montreal, Canada, NAIIM grew up in a bi-cultural
            household: his dad, a first generation Canadian artist
            from Somaliland, and his mom, a French-Canadian teacher.
            Art and education were the foundation of his upbringing.
            However, it wasn’t until he decided to abandon a career in
            medicine that music became his sole focus.
          </p>

          <p>
            NAIIM’s unique amalgamation of rap, pop and R&amp;B
            follows no formula and is an extension of himself. He is
            recognized for his tone and versatility that shine through
            on every single one of his tracks. His music is
            dichotomous, just like him - and brings out every aspect
            of his personality.
          </p>
        </MobileContent>
      </Main>
      <Siding className="siding-2">the artist</Siding>
    </Container>
  )
}

const Title = styled(_Title)`
  position: relative;
  margin-bottom: 2rem;
  left: 1rem;
  display: none;

  @media ${({ theme }) => theme.mediaQuery.small} {
    display: block;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    left: -8rem;
  }
`

const MobileTitle = styled(_Title)`
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.mediaQuery.small} {
    display: none;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: 6rem;
  margin-bottom: 10rem;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: 12rem;
    margin-bottom: 16rem;
  }
`

const Main = styled.div`
  flex: 1 1 100%;
  width: 100%;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
  overflow-x: hidden;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    overflow: visible;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1.5rem;
    grid-auto-rows: minmax(100px, auto);
    position: relative;
    margin-bottom: 8rem;
    padding: 0 1rem;

    @media ${({ theme }) => theme.mediaQuery.medium} {
      padding: 0 1rem;
      margin-bottom: 0;
    }

    .col {
      align-self: start;
    }

    .col-1 {
      grid-column: 2 / 9;
      grid-row: 1;
      position: relative;
      z-index: 2;

      @media ${({ theme }) => theme.mediaQuery.small} {
        grid-column: 2 / 6;
      }

      img {
        width: 100%;
        z-index: 100;
        position: relative;
      }
    }

    .col-2 {
      grid-column: 5 / 9;
      width: 100%;
      grid-row: 1;
      align-self: flex-end;
      position: relative;
      z-index: 3;

      @media ${({ theme }) => theme.mediaQuery.small} {
        grid-column: 4 / 9;
        align-self: center;
      }

      @media (min-width: 640px) {
        grid-column: 5 / 9;
      }

      @media ${({ theme }) => theme.mediaQuery.medium} {
        grid-column: 6 / 9;
      }

      p {
        margin-top: 1rem;
        max-width: 30rem;
        display: none;
        ${Paragraph}

        @media ${({ theme }) => theme.mediaQuery.medium} {
          display: block;
        }
      }
    }

    .background-panel {
      grid-column: 1/7;
      grid-row: 1;
      background: ${({ theme }) => theme.colors.primary};
      top: 5rem;
      position: relative;

      @media ${({ theme }) => theme.mediaQuery.small} {
        grid-column: 1/5;
      }
    }
  }
`

const MobileContent = styled.div`
  padding: 0 2rem;
  max-width: 48rem;
  width: 100%;
  margin: 0 auto;
  display: block;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: none;
  }

  p {
    margin-top: 2rem;
    ${Paragraph}
  }
`

export default OffsetWithSiding
