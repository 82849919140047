import React from "react"
import styled from "styled-components"

import { Title as _Title } from "../../components/globalStyles"

const Footer = () => {
  return (
    <>
      <Container id="contact">
        <div className="content">
          <Title>Contact</Title>
          <ul>
            <li>
              <a href="mailto:business@22sounds.com">
                business@22sounds.com
              </a>
            </li>
          </ul>
        </div>
      </Container>
      <Sponsors>
        <img src="/img/sponsors.png" alt="sponsors content" />
      </Sponsors>
    </>
  )
}

const Sponsors = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 0 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 20rem;
    width: 100%;
  }
`

const Container = styled.footer`
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media ${({ theme }) => theme.mediaQuery.small} {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .content {
    max-width: 53rem;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h2 {
      flex: 1 1 100%;
      text-align: center;
    }

    ul {
      list-style: none;
      font-size: 2rem;
      letter-spacing: -0.1rem;
      margin-top: 2rem;

      @media ${({ theme }) => theme.mediaQuery.small} {
        font-size: 2.5rem;
      }

      @media ${({ theme }) => theme.mediaQuery.medium} {
        font-size: 3.5rem;
      }

      a {
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

const Title = styled(_Title)``

export default Footer
